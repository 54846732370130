<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :loading="dataPending"
      loading-text="Loading matrix... Please wait"
      sort-by="name"
      :items-per-page="15"
      class="risk-matrix-table elevation-1"
    >
      <!-- Extra Header -->
      <template #header>
        <thead class="v-data-table-header ext-header primary darken-1">
          <tr>
            <th></th>
            <th></th>
            <th colspan="2" class="text-center white--text py-3">
              Safety
            </th>
            <th colspan="2" class="text-center white--text py-3">
              Environment
            </th>
            <th></th>
          </tr>
        </thead>
      </template>

      <!-- Likelihood Header -->
      <template v-slot:[`header.likelihood`]="{ header }">
        <pibot-table-dialog-header :header="header" />
      </template>

      <!-- Safety C Header -->
      <template v-slot:[`header.safety_c`]="{ header }">
        <pibot-table-dialog-header :header="header" />
      </template>

      <!-- Safety R Header -->
      <template v-slot:[`header.safety_r`]="{ header }">
        <pibot-table-dialog-header :header="header" />
      </template>

      <!-- Environment C Header -->
      <template v-slot:[`header.environment_c`]="{ header }">
        <pibot-table-dialog-header :header="header" />
      </template>

      <!-- Environment R Header -->
      <template v-slot:[`header.environment_r`]="{ header }">
        <pibot-table-dialog-header :header="header" />
      </template>

      <!-- Highest Risk Header -->
      <template v-slot:[`header.highest_risk`]="{ header }">
        <pibot-table-dialog-header :header="header" />
      </template>

      <!-- Likelihood -->
      <template v-slot:[`item.likelihood`]="props">
        <span v-if="!canEdit || isRevisionData">{{ props.item.likelihood }}</span>
        <v-edit-dialog
          v-else
          :return-value.sync="props.item.likelihood"
          large
          @save="() => saveLocal(props.item)"
          @cancel="snackCancel"
        >
          <v-hover #default="{ hover }">
            <span>
              <span>{{ props.item.likelihood }}</span>
              <v-icon small class="ml-2" :class="{'hidden': !hover}">edit</v-icon>
            </span>
          </v-hover>

          <template #input>
            <div class="pt-3">
            <v-text-field
              autofocus
              v-model="props.item.likelihood"
              label="Likelihood"
              :rules="[minNum1, maxNum6]"
              type="number"
              min="1"
              max="6"
            ></v-text-field>
            </div>
          </template>
        </v-edit-dialog>
      </template>

      <!-- Safety C -->
      <template v-slot:[`item.safety_c`]="props">
        <span v-if="!canEdit || isRevisionData">{{ props.item.safety_c }}</span>
        <v-edit-dialog
          v-else
          :return-value.sync="props.item.safety_c"
          large
          @save="() => saveLocal(props.item)"
          @cancel="snackCancel"
        >
          <v-hover #default="{ hover }">
            <span>
              <span>{{ props.item.safety_c }}</span>
              <v-icon small class="ml-2" :class="{'hidden': !hover}">edit</v-icon>
            </span>
          </v-hover>

          <template #input>
            <div class="pt-3">
            <v-text-field
              autofocus
              v-model="props.item.safety_c"
              label="Safety"
              :rules="[minNum1, maxNum6]"
              type="number"
              min="1"
              max="6"
            ></v-text-field>
            </div>
          </template>
        </v-edit-dialog>
      </template>

      <!-- Safety R -->
      <template v-slot:[`item.safety_r`]="{ item }">
        <pibot-risk-chip fluid :risk="item.safety_r" small />
      </template>

      <!-- Environment C -->
      <template v-slot:[`item.environment_c`]="props">
        <span v-if="!canEdit || isRevisionData">{{ props.item.environment_c }}</span>
        <v-edit-dialog
          v-else
          :return-value.sync="props.item.environment_c"
          large
          @save="() => saveLocal(props.item)"
        >
          <v-hover #default="{ hover }">
            <span>
              <span>{{ props.item.environment_c }}</span>
              <v-icon small class="ml-2" :class="{'hidden': !hover}">edit</v-icon>
            </span>
          </v-hover>

          <template #input>
            <div class="pt-3">
            <v-text-field
              autofocus
              v-model="props.item.environment_c"
              label="Environment"
              :rules="[minNum1, maxNum6]"
              type="number"
              min="1"
              max="6"
            ></v-text-field>
            </div>
          </template>
        </v-edit-dialog>
      </template>

      <!-- Environment R -->
      <template v-slot:[`item.environment_r`]="{ item }">
        <pibot-risk-chip fluid :risk="item.environment_r" small />
      </template>

      <!-- Highest Risk -->
      <template v-slot:[`item.highest_risk`]="{ item }">
        <pibot-risk-chip fluid :risk="item.highest_risk" small />
      </template>
    </v-data-table>

    <!-- PW Confirm -->
    <v-layout justify-end my-2 v-if="canEdit">
      <pibot-pw-confirm small @confirmed="confirmCb" @canceled="snackCancel" :loading="dataPending" :disabled="numChanges === 0">
        <template>
          <v-icon>save</v-icon>
        </template>

        <template #text>
          <p>Do you want to save current risk values?</p>
        </template>
      </pibot-pw-confirm>
    </v-layout>

    <!-- Feedback -->
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template #action>
        <v-icon dark>close</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import RiskChip from '@/components/chips/RiskChip'
import DialogHeader from '@/components/tables/DialogHeader'
import PwConfirm from '@/components/buttons/PasswordConfirm'
import { matrixMixin } from '../utils/mixins/matrix.mixin'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'pibot-asset-risk-matrix-table',
  mixins: [matrixMixin],
  components: {
    'pibot-risk-chip': RiskChip,
    'pibot-pw-confirm': PwConfirm,
    'pibot-table-dialog-header': DialogHeader
  },
  data () {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      minNum1: v => v >= 1 || 'Invalid input',
      maxNum6: v => v <= 6 || 'Invalid input',
      pagination: {},
      headers: [
        {
          text: 'Pipeline',
          align: 'left',
          sortable: false,
          value: 'name',
          class: 'primary darken-1 white--text',
          divider: true
        },
        {
          text: 'Likelihood',
          value: 'likelihood',
          align: 'center',
          sortable: false,
          class: 'primary darken-1 white--text',
          divider: true,
          dialog: {
            title: 'Likelihood',
            text: 'Likelihood as defined in PEPN Risk Matrix'
          }
        },
        {
          text: 'C',
          value: 'safety_c',
          align: 'center',
          sortable: false,
          class: 'primary darken-1 white--text',
          divider: true,
          dialog: {
            title: 'Safety C',
            text: 'Safety Consequences as defined in PEPN Risk Matrix'
          }
        },
        {
          text: 'R',
          value: 'safety_r',
          align: 'center',
          sortable: false,
          class: 'primary darken-1 white--text',
          divider: true,
          dialog: {
            title: 'Safety R',
            text: 'Safety Risk as defined in PEPN Risk Matrix'
          }
        },
        {
          text: 'C',
          value: 'environment_c',
          align: 'center',
          sortable: false,
          class: 'primary darken-1 white--text',
          divider: true,
          dialog: {
            title: 'Environment C',
            text: 'Environment Consequences as defined in PEPN Risk Matrix'
          }
        },
        {
          text: 'R',
          value: 'environment_r',
          align: 'center',
          sortable: false,
          class: 'primary darken-1 white--text',
          divider: true,
          dialog: {
            title: 'Environment R',
            text: 'Environment Risk as defined in PEPN Risk Matrix'
          }
        },
        {
          text: 'Highest Risk',
          value: 'highest_risk',
          align: 'center',
          sortable: false,
          class: 'primary darken-1 white--text',
          divider: false,
          dialog: {
            title: 'Highest Risk',
            text: 'Highest Risk as defined in PEPN Risk Matrix'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.matrix.rows,
      loaded: state => state.matrix.loaded,
      dataPending: state => state.matrix.dataPending,
      isRevisionData: state => state.matrix.isRevisionData,
      revisionsFetched: state => state.matrix.revisionsFetched
    }),
    filteredItems () {
      if (!this.items) return []

      return this.items.filter(({ code }) => {
        return (
          this.$route.params.id.toLowerCase().includes(code.toLowerCase()) ||
          code.toLowerCase().includes(this.$route.params.id.toLowerCase())
        )
      })
    },
    canEdit () {
      return this.$ac.can(this.$me.role).updateAny('risk-matrix').granted
    }
  },
  methods: {
    ...mapMutations({
      setLoadedState: 'matrix/setLoadedState',
      calcMatrix: 'matrix/calcMatrix'
    }),
    ...mapActions({
      fetchItems: 'matrix/socket_fetchMatrixData',
      updateMatrix: 'matrix/socket_updateMatrix',
      fetchRevisions: 'matrix/socket_fetchRevisions',
      refreshMapLayer: 'mapbox/refreshMapLayer'
    }),
    confirmCb (pw) {
      this.updateMatrix(pw)
        .then(() => {
          this.snackSuccess()
          this.setNumChanges(0)
          /**
           * Revisions have been fetched before,
           * which mean this add-on is active and used.
           * Fetch revisions after succesfull save.
           */
          if (this.revisionsFetched) this.fetchRevisions()

          /**
           * Refresh map 'risks' layer
           */
          this.refreshMapLayer('risks')
        })
        .catch(({ message }) => {
          this.snackCancel(message)
        })
    },
    saveLocal (item) {
      this.calcMatrix(item.name)
      this.incrChanges()
    },
    snackSuccess (message) {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = message || 'Update succesful'
    },
    snackCancel (message) {
      console.error(message)
      this.snack = true
      this.snackColor = 'error'
      this.snackText = message || 'Update canceled'
    }
  },
  created () {
    if (this.loaded) return // No need to fetch data again

    this.fetchItems()
      .then(() => {
        this.setLoadedState(true)
      })
      .catch(({ message }) => {
        this.cancel(message)
      })
  }
}
</script>

<style>
.risk-matrix-table tr th {
  border: 0 !important;
}

.risk-matrix-table .ext-header tr th {
  height: unset;
}

/* .risk-matrix-table td {
  border: 0 !important;
} */

.risk-matrix-table .v-icon {
  transition: none !important;
}

.risk-matrix-table .hidden {
  visibility: hidden;
}
</style>
