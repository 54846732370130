<template>
  <v-layout align-center wrap :style="headerStyle">
    <span>{{ header.text }}</span>
    <pibot-info-btn v-if="header.dialog" x-small icon dark :ripple="false">
      <v-icon small>{{ icon }}</v-icon>

      <template #title v-if="header.dialog.title">
        <span>{{ header.dialog.title }}</span>
      </template>

      <template #text v-if="header.dialog.text">
        <span>{{ header.dialog.text }}</span>
      </template>
    </pibot-info-btn>
  </v-layout>
</template>

<script>
import InfoBtn from '@/components/buttons/Info'

export default {
  name: 'pibot-table-dialog-header',
  components: {
    'pibot-info-btn': InfoBtn
  },
  props: {
    header: {
      type: Object, // TableHeader
      required: true
    },
    icon: {
      type: String,
      default: 'help_outline'
    }
  },
  data () {
    return {
      headerStyle: {
        display: 'inline-flex',
        width: 'auto'
      }
    }
  }
}
</script>

<style>

</style>
