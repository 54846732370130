var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"risk-matrix-table elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"loading":_vm.dataPending,"loading-text":"Loading matrix... Please wait","sort-by":"name","items-per-page":15},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{staticClass:"v-data-table-header ext-header primary darken-1"},[_c('tr',[_c('th'),_c('th'),_c('th',{staticClass:"text-center white--text py-3",attrs:{"colspan":"2"}},[_vm._v(" Safety ")]),_c('th',{staticClass:"text-center white--text py-3",attrs:{"colspan":"2"}},[_vm._v(" Environment ")]),_c('th')])])]},proxy:true},{key:"header.likelihood",fn:function(ref){
var header = ref.header;
return [_c('pibot-table-dialog-header',{attrs:{"header":header}})]}},{key:"header.safety_c",fn:function(ref){
var header = ref.header;
return [_c('pibot-table-dialog-header',{attrs:{"header":header}})]}},{key:"header.safety_r",fn:function(ref){
var header = ref.header;
return [_c('pibot-table-dialog-header',{attrs:{"header":header}})]}},{key:"header.environment_c",fn:function(ref){
var header = ref.header;
return [_c('pibot-table-dialog-header',{attrs:{"header":header}})]}},{key:"header.environment_r",fn:function(ref){
var header = ref.header;
return [_c('pibot-table-dialog-header',{attrs:{"header":header}})]}},{key:"header.highest_risk",fn:function(ref){
var header = ref.header;
return [_c('pibot-table-dialog-header',{attrs:{"header":header}})]}},{key:"item.likelihood",fn:function(props){return [(!_vm.canEdit || _vm.isRevisionData)?_c('span',[_vm._v(_vm._s(props.item.likelihood))]):_c('v-edit-dialog',{attrs:{"return-value":props.item.likelihood,"large":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "likelihood", $event)},"update:return-value":function($event){return _vm.$set(props.item, "likelihood", $event)},"save":function () { return _vm.saveLocal(props.item); },"cancel":_vm.snackCancel},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"pt-3"},[_c('v-text-field',{attrs:{"autofocus":"","label":"Likelihood","rules":[_vm.minNum1, _vm.maxNum6],"type":"number","min":"1","max":"6"},model:{value:(props.item.likelihood),callback:function ($$v) {_vm.$set(props.item, "likelihood", $$v)},expression:"props.item.likelihood"}})],1)]},proxy:true}],null,true)},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',[_c('span',[_vm._v(_vm._s(props.item.likelihood))]),_c('v-icon',{staticClass:"ml-2",class:{'hidden': !hover},attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)})],1)]}},{key:"item.safety_c",fn:function(props){return [(!_vm.canEdit || _vm.isRevisionData)?_c('span',[_vm._v(_vm._s(props.item.safety_c))]):_c('v-edit-dialog',{attrs:{"return-value":props.item.safety_c,"large":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "safety_c", $event)},"update:return-value":function($event){return _vm.$set(props.item, "safety_c", $event)},"save":function () { return _vm.saveLocal(props.item); },"cancel":_vm.snackCancel},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"pt-3"},[_c('v-text-field',{attrs:{"autofocus":"","label":"Safety","rules":[_vm.minNum1, _vm.maxNum6],"type":"number","min":"1","max":"6"},model:{value:(props.item.safety_c),callback:function ($$v) {_vm.$set(props.item, "safety_c", $$v)},expression:"props.item.safety_c"}})],1)]},proxy:true}],null,true)},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',[_c('span',[_vm._v(_vm._s(props.item.safety_c))]),_c('v-icon',{staticClass:"ml-2",class:{'hidden': !hover},attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)})],1)]}},{key:"item.safety_r",fn:function(ref){
var item = ref.item;
return [_c('pibot-risk-chip',{attrs:{"fluid":"","risk":item.safety_r,"small":""}})]}},{key:"item.environment_c",fn:function(props){return [(!_vm.canEdit || _vm.isRevisionData)?_c('span',[_vm._v(_vm._s(props.item.environment_c))]):_c('v-edit-dialog',{attrs:{"return-value":props.item.environment_c,"large":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "environment_c", $event)},"update:return-value":function($event){return _vm.$set(props.item, "environment_c", $event)},"save":function () { return _vm.saveLocal(props.item); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"pt-3"},[_c('v-text-field',{attrs:{"autofocus":"","label":"Environment","rules":[_vm.minNum1, _vm.maxNum6],"type":"number","min":"1","max":"6"},model:{value:(props.item.environment_c),callback:function ($$v) {_vm.$set(props.item, "environment_c", $$v)},expression:"props.item.environment_c"}})],1)]},proxy:true}],null,true)},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',[_c('span',[_vm._v(_vm._s(props.item.environment_c))]),_c('v-icon',{staticClass:"ml-2",class:{'hidden': !hover},attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)})],1)]}},{key:"item.environment_r",fn:function(ref){
var item = ref.item;
return [_c('pibot-risk-chip',{attrs:{"fluid":"","risk":item.environment_r,"small":""}})]}},{key:"item.highest_risk",fn:function(ref){
var item = ref.item;
return [_c('pibot-risk-chip',{attrs:{"fluid":"","risk":item.highest_risk,"small":""}})]}}],null,true)}),(_vm.canEdit)?_c('v-layout',{attrs:{"justify-end":"","my-2":""}},[_c('pibot-pw-confirm',{attrs:{"small":"","loading":_vm.dataPending,"disabled":_vm.numChanges === 0},on:{"confirmed":_vm.confirmCb,"canceled":_vm.snackCancel},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('p',[_vm._v("Do you want to save current risk values?")])]},proxy:true}],null,false,4094594932)},[[_c('v-icon',[_vm._v("save")])]],2)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-icon',{attrs:{"dark":""}},[_vm._v("close")])]},proxy:true}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }